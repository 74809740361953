export const DEFAULT_REVALIDATE_TIME = 0;

export const BASE_BACKEND_URL = process.env.NEXT_PUBLIC_BASE_BACKEND_URL;
// export const BASE_BACKEND_URL = 'http://127.0.0.1:8000';

export const BASE_SERVER_URL = '';

export const BASE_ADMIN_URL = process.env.NEXT_PUBLIC_BASE_ADMIN_URL;

export const BASE_INTEGRATION_SERVICE_URL =
  process.env.NEXT_PUBLIC_BASE_INTEGRATION_SERVICE_URL;
export const BASE_INTEGRATION_MONITOR_URL =
  process.env.NEXT_PUBLIC_INTEGRATION_MONITOR_URL;

export const BASE_DASHBOARD_URL = process.env.NEXT_PUBLIC_BASE_DASHBOARD_URL;
