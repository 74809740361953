import type { NextRequest } from 'next/server';
import { getCookies } from './cookies';

export async function getHeaders(req?: NextRequest, includeOrg?: boolean) {
  const { strCookie, cookies } = await getCookies();
  const { token, user_id, customer_id, segments, organization_id } = cookies;

  if (!token || !user_id || !customer_id || !organization_id) {
    return {};
  }

  return {
    headers: {
      Cookie: strCookie,
      Authorization: `Token ${token}`,
      'X-PIGELLO-USER-ID': user_id,
      ...(segments && { 'X-PIGELLO-CHOSEN-SEGMENT': segments }),
      'X-PIGELLO-CUSTOMER-ID': customer_id,
      // env,client-type,version
      'X-PIGELLO-CLIENT-TYPE': `${process.env.NEXT_PUBLIC_CLIENT_TYPE},frontend,1.0.0`,
      ...(includeOrg && { 'X-PIGELLO-ORGANIZATION-ID': organization_id }),
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...getBackendHeaders(req),
    },
  };
}

export function getBackendHeaders(req?: NextRequest): HeadersInit {
  if (!req) return {};

  const ip = req.headers.get('X-Forwarded-For') ?? '127.0.0.1';

  const datadogTraceId = req.headers.get('x-datadog-trace-id');
  const datadogSamplingPriority = req.headers.get(
    'x-datadog-sampling-priority'
  );
  const datadogOrigin = req.headers.get('x-datadog-origin');
  const datadogParentId = req.headers.get('x-datadog-parent-id');
  return {
    'X-Forwarded-Host': 'pigello-frontend',
    'X-Forwarded-For': ip,
    ...(datadogTraceId && { 'X-Datadog-Trace-Id': datadogTraceId }),
    ...(datadogSamplingPriority && {
      'X-Datadog-Sampling-Priority': datadogSamplingPriority,
    }),
    ...(datadogOrigin && { 'X-Datadog-Origin': datadogOrigin }),
    ...(datadogParentId && { 'X-Datadog-Parent-Id': datadogParentId }),
  };
}
